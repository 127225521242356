<template>
  <div class="alipayCard">
    <div class="cardList" v-if="alipayList.length">
      <div v-for="(item,index) in alipayList" :key="'alipay'+index" class="bankCard alipay" :class="{active: item.account == selected._bankAccount||item.account == selected.account}" @click="handleCardChange(item)">
        <span class="no text-ellipsis">{{item.secrecyAccount}}</span>
        <span class="btn" @click="handleDel(item.id)">解绑</span>
        <span class="btn" @click.stop="handleEdit(item)">编辑</span>
      </div>
    </div>
    <template v-else>
      <empty text="暂未添加支付宝"></empty>
    </template>
    <el-dialog :close-on-click-modal="false" :append-to-body="true" :visible.sync="visible" width="500px" class="addCardBox" @closed="handleClosed">
      <div slot="title">
        <h3>{{isEdit?"编辑":"添加"}}支付宝账号</h3>
        <p> 请{{isEdit?"编辑":"添加"}}<span>{{user.name}}</span>的支付宝账号 </p>
      </div>
      <el-form :model="cardForm" :rules="cardFormRules" ref="cardForm" label-width="100px" size="small">
        <el-form-item label="支付宝账号" prop="account">
          <el-input v-model="cardForm.account" placeholder="请绑定本人的支付宝账号" maxlength="50"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('cardForm')">取 消</el-button>
        <el-button size="small" type="primary" @click="submitForm('cardForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/list";
import empty from "@/components/empty/index.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    empty,
  },
  props: {
    addAlipayVisible: {
      type: Boolean,
      default: false,
    },
    //选择模式的比较对象
    selectInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      isEdit: false,
      alipayList: [],
      cardForm: {
        account: "",
      },
      cardFormRules: {
        account: [
          { required: true, message: "请输入支付宝号", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),

    visible: {
      get: function () {
        return this.addAlipayVisible;
      },
      set: function (v) {
        this.$emit("setVisible", v);
      },
    },
    selected: {
      get: function () {
        return this.selectInfo;
      },
      set: function (v) {},
    },
  },
  created() {
    this.getAlipayList();
    this.cardForm.userName = this.user.name;
  },
  methods: {
    getAlipayList() {
      api.getAlipayList().then((res) => {
        this.alipayList = res.data.map((v) => {
          let secrecyAccount = "";
          if (v.account.indexOf("@") == -1) {
            secrecyAccount =
              v.account.substring(0, 3) +
              "****" +
              v.account.substring(v.account.length - 4);
          } else {
            secrecyAccount =
              v.account.substring(0, 3) +
              "****" +
              v.account.substring(v.account.indexOf("@"));
          }

          this.$set(v, "secrecyAccount", secrecyAccount);
          return v;
        });
        this.$emit("setAlipayNumber", this.alipayList.length);
      });
    },
    handleCardChange(item) {
      if (JSON.stringify(this.selectInfo) != "{}") {
        Object.assign(this.selected, {
          _bankAccount: item.account,
          _bankName: item.bank_name,
          secrecyAccount: item.secrecyAccount,
        });
        this.$emit("selected", this.selected);
      }
    },
    handleEdit(item) {
      this.cardForm.account = item.account;
      this.cardForm.id = item.id;
      this.visible = true;
      this.isEdit = true;
    },
    handleDel(id) {
      this.$confirm("该操作将删除该支付宝账号，确定要解绑？", "解绑提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .deleteBankCard({}, { method: "delete", subUrl: id })
            .then((res) => {
              if (res.status == 200) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: "解绑成功",
                });
                this.getAlipayList();
              } else {
                this.$message({
                  showClose: true,
                  type: "error",
                  message: res.message,
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "已取消解绑",
          });
        });
    },
    handleClosed() {
      this.cardForm = {
        userName: "",
        bankName: "",
        account: "",
      };
      this.visible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let temp = Object.assign({}, this.cardForm);
          api.alipaySave(temp, { method: "POST" }).then((res) => {
            if (res.status == "200") {
              this.$message({
                showClose: true,
                message: "操作成功",
                type: "success",
              });
              this.handleClosed();
              this.getAlipayList();
            } else {
              this.$message({
                showClose: true,
                message: res.message,
                type: "warning",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.handleClosed();
    },
  },
};
</script>

<style lang="less" scoped>
.addCardBox {
  /deep/ .el-dialog__header {
    h3 {
      margin: 0 0 10px 0;
      font-size: 18px;
      line-height: 30px;
    }

    p {
      margin: 0;
      font-size: 14px;
      span {
        color: #e78320;
      }
    }
  }
}
.bankCard {
  position: relative;
  display: inline-block;
  width: 169px;
  height: 59px;
  padding: 10px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 8px 12px;
  cursor: pointer;
  top: 0;
  transition: top 500ms;
  -webkit-transition: top 500ms;

  &:hover {
    top: -6px;
  }

  &.active {
    top: -2px;
    &:after {
      content: " ";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 55px;
      height: 14px;
      background: url("../../../assets/selected.png") center center no-repeat;
    }
  }

  .no {
    display: block;
    color: #fff;
    font-size: 12px;
    margin-top: 22px;
  }

  .btn {
    position: absolute;
    right: 10px;
    top: 10px;
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    cursor: pointer;
    & + .btn {
      right: 52px;
    }
  }

  .identify {
    position: absolute;
    right: 52px;
    top: 10px;
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    cursor: pointer;
  }

  &.alipay {
    background-image: url(./assets/alipay.png);
  }
}
</style>