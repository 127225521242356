<!--
 * @Descripttion:  
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 11:12:28
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-28 14:10:22
-->
<template>
  <el-card class="box-card" shadow="never" style="min-height: 750px">
    <div slot="header" class="clearfix">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">我的协议</el-breadcrumb-item>
        <el-breadcrumb-item>电签协议</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-timeline class="timeline">
      <el-timeline-item timestamp="基本信息" placement="top">
        <el-row class="baseInfo">
          <el-col :span="8">
            <div class="item">
              <label>用户名:</label>
              <span class="value">{{ user.name }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item">
              <label>手机号码:</label>
              <span class="value">{{ user.mobile }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item">
              <label>性别:</label>
              <span class="value"> {{ user.gender ? "男" : "女" }} <span class="icon_gender" :class="{ man: user.gender, woman: !user.gender }"></span>
              </span>
            </div>
          </el-col>
        </el-row>
      </el-timeline-item>
      <el-timeline-item timestamp="选择收款账号" placement="top">
        <div class="headerRight rightLittle">
          <span v-if="user._bankName!=null" class="bankname"> {{ user._bankName }} </span>
          <span v-if="user._bankName!=null" class="no">{{ user._bankAccount }}</span>
          <span v-if="user._bankMobile!=null&&user._bankMobile!=''" class="bankname"> 预留手机号 </span>
          <span v-if="user._bankMobile!=null&&user._bankMobile!=''" class="no">{{ user._bankMobile }}</span>
          <el-link type="primary" style="float: right" icon="el-icon-circle-plus" @click="addBankVisible = true" v-if="cardListLength < 5">添加收款账号</el-link>
        </div>
        <el-card shadow="never">
          <bank-card
                  @setCardNumber="cardListLength = $event"
                  :addBankVisible='addBankVisible'
                  @setVisible="addBankVisible = $event"
                  :selectInfo="user"
                  :canReg="true"
                  :ocompanyId="this.$route.query.ocompanyId"
                  @selected="method1" />
        </el-card>
      </el-timeline-item>
      <!-- <el-timeline-item timestamp="选择支付宝" placement="top">
        <div class="headerRight">
          <span v-if="user._bankName!=null && user._bankName =='支付宝'" class="bankname" style="margin-left:20px"> {{  user._bankName }} </span>
          <span v-if="user._bankName!=null && user._bankName =='支付宝'" class="no">{{ user._bankAccount }}</span>
          <el-link v-if="alipayLength == 0" type="primary" style="float: right" icon="el-icon-circle-plus" @click="addAlipayVisible = true">添加支付宝</el-link>
        </div>
        <el-card shadow="never">
          <alipay-card @setAlipayNumber="alipayLength = $event" :addAlipayVisible='addAlipayVisible' @setVisible="addAlipayVisible = $event" :selectInfo="user" @selected="user=$event" />
        </el-card>
      </el-timeline-item> -->
      <el-timeline-item timestamp="身份验证" placement="top">
        <div class="headerRight" v-if="user.certificationStatus == 'AUTHENTICATIONOK'">
          <el-row>
            <el-col :span="8">
              <el-button type="success" round size="mini">已认证</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="headerRight" v-else>
          <el-row>
            <el-col :span="8">
              <el-button type="danger" round size="mini">未认证</el-button>
            </el-col>
            <el-col :span="8" v-if="user.papersType"> 证件类型: <span class="value" v-if="user.papersType == 1">居民身份证</span>
              <span class="value" v-else-if="user.papersType == 2">台湾来往大陆通行证</span>
              <span class="value" v-else-if="user.papersType == 3">澳门来往大陆通行证</span>
              <span class="value" v-else-if="user.papersType == 4">香港来往大陆通行证</span>
              <span class="value" v-else-if="user.papersType == 5">护照</span>
            </el-col>
            <el-col :span="8" style="text-align: right" v-if="user.idNumber"> 证件号码: <span class="value">{{ user.idNumber }}</span>
            </el-col>
          </el-row>
        </div>
        <el-card shadow="never" v-if="user.certificationStatus == 'AUTHENTICATIONOK'">
          <div class="front">
            <div class="content">
              <p> 姓名 <span>{{ user.name }}</span>
              </p>
              <p> 证件号 <span class="block">{{ user.idNumber }}</span>
              </p>
            </div>
          </div>
          <div class="back"></div>
        </el-card>
        <el-card shadow="never" v-else>
          <upload-pic :front="true" class="upload" @setImageUrl="idFront = $event"></upload-pic>
          <upload-pic :back="true" class="upload" @setImageUrl="idBack = $event"></upload-pic>
          <img src="@/assets/upload_require.png" class="identityImg" />
        </el-card>
        <el-tabs class="selectIdentification" v-model="activeName"  v-if="user.certificationStatus !== 'AUTHENTICATIONOK'">
            <el-tab-pane v-if="flag_bankcard4" label="银行卡四要素认证" name="bankcard4"></el-tab-pane>
            <el-tab-pane  v-if="flag_card" label="银行卡三要素认证" name="card"></el-tab-pane>
            <el-tab-pane  v-if="flag_telecom3" label="手机号认证" name="telecom3"></el-tab-pane>
        </el-tabs>
        <!--认证信息-->
        <ul class="userCardInfoContent" v-if="((user._bankAccount!==null&&user._bankAccount!==undefined&&user._bankAccount!==''&&activeName!=='telecom3')||(activeName==='telecom3'))&&user.certificationStatus !== 'AUTHENTICATIONOK'">
            <li class="userCardInfoContentItem">
                <p class="label">
                    <label>
                     姓名<i></i>
                    </label>
                </p>
                {{ user.name }}
            </li>    
            <li class="userCardInfoContentItem">
                <p class="label">
                    <label>
                        证件号码<i></i>
                    </label>
                </p>
                {{ user.idNumber }}
            </li>   
            <li v-if="activeName!=='telecom3'" class="userCardInfoContentItem">
                <p class="label">
                    <label>
                        银行卡号<i></i>
                    </label>
                </p>
                {{ user._bankAccount }}
            </li>   
            <li v-if="activeName!=='telecom3'" class="userCardInfoContentItem">
                <p class="label">
                    <label>
                    银行名称<i></i>
                    </label>
                </p>
                {{ user._bankName }}
            </li>   
            <li v-if="activeName!=='card'" class="userCardInfoContentItem bankMobile">
                <p class="label">
                    <template v-if="activeName ==='telecom3'">
                        <label>手机号<i></i></label>
                    </template>
                    <template v-else>
                        预留手机号
                    </template>
                        
                </p>
                <el-input v-model="user.mobile" :autofocus="true" v-if="activeName ==='telecom3'"  placeholder="请输入当前账户绑定手机号"></el-input>
                <el-input v-model="user._bankMobile" v-else :autofocus="true"  placeholder="请输入银行预留手机号"></el-input>
            
            </li>   
        </ul>
      </el-timeline-item>
      <el-timeline-item timestamp="开始电签" placement="top" v-if="user.certificationStatus == 'AUTHENTICATIONOK'">
        <div class="submit_box">
          <el-checkbox v-model="checked">同意</el-checkbox>
          <el-link type="primary" @click="handleHelp">《电签协议》电签帮助</el-link>
          <el-button type="primary" @click="sign" :loading="signLoading" :disabled="isDisabled">开始电签</el-button>
        </div>
      </el-timeline-item>
      <el-timeline-item timestamp="开始认证" placement="top" v-else>
        <div class="submit_box">
          <el-button type="primary" @click="handleIndentify" :loading="identityLoading">开始认证</el-button>
        </div>
      </el-timeline-item>
    </el-timeline>

    <el-dialog
    title="短信验证"
    :show-close="false"
    :visible.sync="showCode"
    width="360px"
    top="30vh"
    center
   >
    <div class="getCodeContent">
        <p class="item">
          <label>
            当前手机：
          </label> 
          <template v-if="activeName ==='telecom3'">
                {{ user.mobile }}
            </template>
            <template v-else>
                {{ user._bankMobile }}
            </template>
          
        </p>
        <div class="code-input">
            <el-input
            placeholder="请输入验证码"
            v-model="code"
            clearable>
            </el-input>
            <span class="countDownBox"  v-show="isOn">
                {{ time }}秒后可重新获取
            </span>
            <span @click="handleCode" class="optBtn" v-show="!isOn">{{ codeText }}</span>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="cancelPhone">取 消</el-button>
        <el-button type="primary" @click="handleClose">确认提交</el-button>
    </span>
    </el-dialog>
  </el-card>
</template>

<script>
import api from "@/api/list";
import bankCard from "./components/bankCard.vue";
import alipayCard from "./components/alipayCard.vue";
import uploadPic from "@/components/uploadID/index.vue";

//import { mapGetters } from "vuex";
export default {
  components: {
    bankCard,
    alipayCard,
    uploadPic,
  },
  data() {
    return {
      checked: false,
      user: {
        _bankAccount: null,
        _bankName: null,
        _bankMobile:null,
         mobile:null,
         certificationStatus:null
      },
      identityLoading: false,
      signLoading: false,
      idFront: "",
      idBack: "",
      isDisabled: true,
      contractId: this.$route.query.contractId,
      cardListLength: 0,
      addBankVisible: false,
      addAlipayVisible: false,
      alipayLength: 0,
      activeName: '', //认证方式
      flag_bankcard4:false, //银行四要素认证
      flag_card:false, //银行三要素认证
      flag_telecom3:false, //手机号认证
      showCode:false, //是否展示输入手机验证码弹窗
      isOn:false,//是否展示倒计时
      code:'',//手机验证码
      time: 60 ,
      timer:null,//倒计时定时器
      codeText:"重新获取"
    };
  },
  computed: {
    //...mapGetters(["user"]),
  },
  watch: {
    checked: function (v) {
      this.isDisabled = !v;
    },
    // cardList: {
    //   handler: function () {
    //     this.handleInfoChange();
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    idFront: function (now) {
      let that = this;
      this.imgPreview(now, function (data) {
        that.user.idFront = data;
      });
    },
    idBack: function (now) {
      let that = this;
      this.imgPreview(now, function (data) {
        that.user.idBack = data;
      });
    },
  },
  mounted() {
    this.getInfo(this.contractId);
  },
  methods: {
    //点击取消
    cancelPhone(){
        this.showCode = false;
        this.identityLoading = false;
    },
    //重新获取验证码
    handleCode(){
        let phone = this.user._bankMobile;
        if(this.activeName==='telecom3'){
            phone = this.user.mobile;
        }
        if (!(/^[1][0-9]{10}$/.test(phone))) { 
            this.$message({
                showClose: true,
                message: "请输入正确手机号！",
                type: "error",
                });
            return false
        }

      api
        .reSendAuthCode({ mobile: phone,identityType:this.activeName },{ method: "post" })
        .then((res) => {
          if (res.status.toString() == "200") {
            this.isOn = true;
            this.countDown();
            this.codeText = "重新获取";
          } else {
            this.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
            });
          }
         
        })
        .catch((error) => {
            this.isOn = false;
        });
    },
    //手机号 校验认证
    handleClose(){
        if(this.code===""||this.code==undefined){
            this.$message({
                showClose: true,
                message: "请填写验证码！",
                type: "error",
            });
             return false;
        }

        api.authCodeAudit(
            {
                authcode:this.code,//验证码
                identityType:this.activeName,
                bankCard: this.user._bankAccount
            },{
                method:'post'
            }
        ).then((res)=>{
            
            var papersType = this.user.papersType;
           if(res.status.toString()==='200'){
            let msg = "";
                if (papersType !== "1") {
                  msg = "上传成功";
                } else {
                  msg = "认证成功";
                }
                this.showCode = false;
                this.identityLoading = false;
                this.time = 60;
                clearInterval(this.timer);
                this.isOn = false;
                this.code = "";
                this.getInfo(this.$route.query.contractId);
                this.$message({
                    showClose: true,
                    message: msg,
                    type: "success",
                });
             
                
           }else if(res.status.toString()==='30005'){ //30005 .验证码错误或空
                this.$message({
                    showClose: true,
                    message: "验证码空！",
                    type: "error",
                });   
           }else{ //业务错误
                this.$message({
                    showClose: true,
                    message: "验证码错误!",
                    type: "error",
                });   
           }
          
        }).catch(err=>{
           
           
            
        })
    },
    showImage(){
        let front = process.env.VUE_APP_FILE_PATH+this.user.front;
       
        this.getBase64(front,dataURL=>{
                this.user.idFront=dataURL
                let img = document.getElementsByClassName("uploader_section  front")[0];
                img.style.backgroundImage = `url(${dataURL})`;
             
                
        })
        let back = process.env.VUE_APP_FILE_PATH+this.user.back;
        this.getBase64(back,dataURL=>{
                this.user.idBack=dataURL
                let img = document.getElementsByClassName("uploader_section back")[0];
                img.style.backgroundImage = `url(${dataURL})`;
                
        })
    },
        // url 转base64
        getBase64(url, callback) {
        var Img = new Image(),
            dataURL = '';
        Img.src = url + '?v=' + Math.random();
        Img.setAttribute('crossOrigin', 'Anonymous');
        Img.onload = function() {
            var canvas = document.createElement('canvas'),
            width = Img.width,
            height = Img.height;
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
            dataURL = canvas.toDataURL('image/jpeg');
            return callback ? callback(dataURL) : null;
        };
    },
    getInfo(contractId) {
      api.employeeGetAllInfo({ contractId }).then((res) => {
        if (res.status == "200") {
          Object.assign(this.user, res.data);
          if(this.user.front){
                this.showImage();
          }
          console.log("info",this.user)
          console.log("res.data",res.data.cerficationTypes)
          let cerficationTypes = res.data.cerficationTypes;
          if(cerficationTypes === "" || cerficationTypes === null){ //没有配置认证方式
            this.activeName = "bankcard4"
            this.flag_bankcard4 = true
          }else{
            let cerficationTypesArray = cerficationTypes.split(",");
            this.activeName = cerficationTypesArray[0];
            cerficationTypesArray.map(item=>{
                this['flag_'+item] = true;
            })

          }
          this.$set(this.user, "_bankAccount", this.user.bankAccount);
          this.$set(this.user, "_bankName", this.user.bankName);
          this.$set(this.user, " _bankMobile", this.user.bankMobile)
        }
      });
    },
    method1(item){
      console.log('click',item);
      //this.user = item;

    },
    createEmployeeContract(obj) {
      // 向e签宝发起协议申请
      this.signLoading = true;
      api.createContract(obj, { method: "post" }).then((res) => {
        this.signLoading = false; //关闭加载效果
        if (res.status == "200") {
          window.open(res.data, "_self");
        } else {
          this.$message({
            showClose: true,
            message: "签署协议失败，请稍后再试！",
            type: "error",
          });
        }
      });
    },
    sign() {
      if (this.user.certificationStatus == "AUTHENTICATIONOK") {
        // 已认证
        if (
          typeof this.user._bankName != "undefined" &&
          typeof this.user._bankAccount != "undefined"
        ) {
          // 银行卡名称和银行卡账号不为空(协议绑定了银行卡)
          if (
            typeof this.user.bankName != "undefined" &&
            typeof this.user.bankAccount != "undefined"
          ) {
            if (this.user.signUrl != null && this.user.signUrl != "") {
              window.open(this.user.signUrl, "_self");
            } else {
              // 向e签宝发起协议申请
              this.createEmployeeContract({
                id: this.contractId,
                url: "pcUrl",
              });
            }
          } else {
            api
              .bank(
                {
                  name: this.user._bankName,
                  bankNo: this.user._bankAccount,
                  contractId: this.contractId,
                },
                { method: "post" }
              )
              .then((res) => {
                if (res.status == "200") {
                  if (this.user.signUrl != null) {
                    window.open(this.user.signUrl, "_self");
                  } else {
                    // 向e签宝发起协议申请
                    this.createEmployeeContract({
                      id: this.contractId,
                      url: "pcUrl",
                    });
                  }
                }
              });
          }
        } else {
          this.$message({
            showClose: true,
            message: "请先选择收款账号！",
            type: "error",
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: "请先完成实名认证！",
          type: "error",
        });
      }
    },
      //认证 四要素 和运营商三要素
    authentication4(){
        let phone = this.user._bankMobile;
        if(this.activeName==='telecom3'){
            phone = this.user.mobile;
        }
      
        if (!(/^[1][0-9]{10}$/.test(phone))) { 
            this.$message({
                showClose: true,
                message: "请输入正确手机号！",
                type: "error",
                });
            return false
        }
        api
        .idImage2(
          {
            front: this.user.idFront,
            back: this.user.idBack,
            bankCard: this.user._bankAccount,
            mobileNo:phone,
            identityType:this.activeName
          },
          { method: "post" }
        )
        .then((res) => {
          this.identityLoading = false;
          if (res.status == "200") {
            var papersType = this.user.papersType;
            if (res.status != "200") {
              if (papersType !== "1") {
                this.$message({
                  showClose: true,
                  message: "上传失败," + res.message,
                  type: "error",
                });
              } else {
                this.$message({
                  showClose: true,
                  message: "认证失败," + res.message,
                  type: "error",
                });
              }
              return;
            } else if (res.status == "200") {
                if(res.data&&res.data==='junziqian'){
                    let msg = "";
                    var papersType = this.user.papersType;
                    if (papersType !== "1") {
                    msg = "上传成功";
                    } else {
                    msg = "认证成功";
                    }
                    this.showCode = false;
                    this.identityLoading = false;
                    this.time = 60;
                    clearInterval(this.timer);
                    this.isOn = false;
                    this.code = "";
                    this.getInfo(this.$route.query.contractId);
                    this.$message({
                        showClose: true,
                        message: msg,
                        type: "success",
                    });
                    return false
              
                }
                //显示输入手机号 验证码
                this.countDown();
                this.showCode = true;
                this.isOn = true;
            }
          } else {
            //验证失败，请检查上传照片是否完成
            this.$message({
              showClose: true,
              message: res.message,
              type: "error",
            });
          }
        });
    },
    //倒计时
    countDown(){
         this.time = 59;
         clearInterval(this.timer);
         this.timer = setInterval(()=>{
            --this.time;
            if(this.time<1){
                clearInterval(this.timer);
                this.isOn = false
            }
          
        },1000)
    },
    //认证 银行卡三要素
    authentication3(){
        api
        .idImage(
          {
            front: this.user.idFront,
            back: this.user.idBack,
            bankCard: this.user._bankAccount,
          },
          { method: "post" }
        )
        .then((res) => {
          this.identityLoading = false;
          if (res.status == "200") {
            var papersType = this.user.papersType;
            if (res.status != "200") {
              if (papersType !== "1") {
                this.$message({
                  showClose: true,
                  message: "上传失败," + res.message,
                  type: "error",
                });
              } else {
                this.$message({
                  showClose: true,
                  message: "认证失败," + res.message,
                  type: "error",
                });
              }
              return;
            } else if (res.status == "200") {
              var msg = "";
              if (papersType !== "1") {
                msg = "上传成功";
              } else {
                msg = "认证成功";
              }
              this.$message({
                showClose: true,
                message: msg,
                type: "success",
              });
              this.getInfo(this.$route.query.contractId);
            }
          } else {
            //验证失败，请检查上传照片是否完成
            this.$message({
              showClose: true,
              message: res.message,
              type: "error",
            });
          }
        });
    },
    handleIndentify() {
        if(this.activeName!=='telecom3'){
            if (this.user._bankAccount == "" || this.user._bankAccount == undefined) {
                this.$message({
                showClose: true,
                message: "请添加收款账号!",
                type: "error",
                });
                return false;
            }
        }
   
      if (
        this.user.idFront == "" ||
        this.user.idFront == undefined ||
        this.user.idBack == "" ||
        this.user.idBack == undefined
      ) {
        this.$message({
          showClose: true,
          message: "请确认是否上传完整的证件正反面!",
          type: "error",
        });
        return false;
      }

      this.identityLoading = true;
      if(this.activeName==='card'){
            this.authentication3();
        }else{
            this.authentication4();
        }


    },

    imgPreview(file, callback) {
      let self = this;
      let Orientation;

      // 看支持不支持FileReader
      if (!file || !window.FileReader) return;

      if (/^image/.test(file.type)) {
        // 创建一个reader
        let reader = new FileReader();
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file);
        // 读取成功后的回调
        reader.onloadend = function () {
          let result = this.result;
          let img = new Image();
          img.src = result;
          //判断图片是否大于100K,是就直接上传，反之压缩图片
          if (this.result.length <= 100 * 1024) {
            callback(this.result);
          } else {
            img.onload = function () {
              let data = self.compress(img, Orientation);
              callback(data);
            };
          }
        };
      }
    },
    compress(img, Orientation) {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      //瓦片canvas
      let tCanvas = document.createElement("canvas");
      let tctx = tCanvas.getContext("2d");
      //let initSize = img.src.length;
      let width = img.width;
      let height = img.height;
      //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio;
      if ((ratio = (width * height) / 4000000) > 1) {
        //console.log("大于400万像素");
        ratio = Math.sqrt(ratio);
        width /= ratio;
        height /= ratio;
      } else {
        ratio = 1;
      }
      canvas.width = width;
      canvas.height = height;
      //        铺底色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //如果图片像素大于100万则使用瓦片绘制
      let count;
      if ((count = (width * height) / 1000000) > 1) {
        //console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
        //            计算每块瓦片的宽和高
        let nw = ~~(width / count);
        let nh = ~~(height / count);
        tCanvas.width = nw;
        tCanvas.height = nh;
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(
              img,
              i * nw * ratio,
              j * nh * ratio,
              nw * ratio,
              nh * ratio,
              0,
              0,
              nw,
              nh
            );
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height);
      }
      //修复ios上传图片的时候 被旋转的问题
      if (Orientation != "" && Orientation != 1) {
        switch (Orientation) {
          case 6: //需要顺时针（向左）90度旋转
            this.rotateImg(img, "left", canvas);
            break;
          case 8: //需要逆时针（向右）90度旋转
            this.rotateImg(img, "right", canvas);
            break;
          case 3: //需要180度旋转
            this.rotateImg(img, "right", canvas); //转两次
            this.rotateImg(img, "right", canvas);
            break;
        }
      }
      //进行最小压缩
      let ndata = canvas.toDataURL("image/jpeg", 0.1);
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
      return ndata;
    },
    rotateImg(img, direction, canvas) {
      //最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0;
      const max_step = 3;
      if (img == null) return;
      //img的高度和宽度不能在img元素隐藏后获取，否则会出错
      let height = img.height;
      let width = img.width;
      let step = 2;
      if (step == null) {
        step = min_step;
      }
      if (direction == "right") {
        step++;
        //旋转到原位置，即超过最大值
        step > max_step && (step = min_step);
      } else {
        step--;
        step < min_step && (step = max_step);
      }
      //旋转角度以弧度值为参数
      let degree = (step * 90 * Math.PI) / 180;
      let ctx = canvas.getContext("2d");
      switch (step) {
        case 0:
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0);
          break;
        case 1:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, 0, -height);
          break;
        case 2:
          canvas.width = width;
          canvas.height = height;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, -height);
          break;
        case 3:
          canvas.width = height;
          canvas.height = width;
          ctx.rotate(degree);
          ctx.drawImage(img, -width, 0);
          break;
      }
    },
    handleHelp() {
      let routeData = this.$router.resolve({
        path: "/help",
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.getCodeContent{
    font-size: 15px;
    padding: 0 12px;
    .item{
      padding-bottom: 20px;
    }
    .code-input{
        position: relative;
        border-bottom: 1px solid #ccc;
        .el-input{
            width: 150px;
            /deep/.el-input__inner{
                border: none;
                padding-left: 0;
            }
      
        }
        .countDownBox{
            position: absolute;
            right: 10px;
            height: 40px;
            line-height: 40px;
        }
        .optBtn{
            position: absolute;
            right: 30px;
            height: 40px;
            line-height: 40px;
            color: #8493FA;
            cursor: pointer;
        }
    }
}
.box-card {
  /deep/ .el-breadcrumb {
    font-size: 16px;
    padding-bottom: 8px;
  }
}

.front,
.back {
  display: inline-block;
  vertical-align: middle;
  width: 240px;
  height: 138px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 8px;
}
.front {
  padding: 30px 0 0 94px;
  background-image: url("../../assets/identity_front.png");
  p {
    font-size: 16px;
    color: #666;
    margin: 0;
    line-height: 20px;
    margin-bottom: 12px;
    span {
      color: #333;
      &.block {
        font-size: 12px;
        display: block;
      }
    }
  }
}
.back {
  background-image: url("../../assets/identity_back.png");
}

.submit_box {
  text-align: right;
  line-height: 30px;
  padding: 20px 0;

  /deep/ .el-button {
    margin-left: 20px;
  }

  /deep/ .el-link {
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }
}
.upload {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.identityImg {
  display: inline-block;
  vertical-align: middle;
  width: 276px;
}
.headerRight {
  position: absolute;
  right: 0;
  top: -2px;
  width: 87%;
  line-height: 24px;
  color: #999;
  .no {
    color: #8493fa;
    margin-left: 12px;
  }

  .bankname {
    color: #999;
  }

  .value {
    color: #333;
  }

  /deep/ .el-button {
    padding: 3px 6px;
  }
  &.rightLittle{
    width: 83%;
  }
}
.timeline {
  padding-top: 20px;
  /deep/ .el-timeline-item__timestamp {
    position: relative;
    font-size: 18px;
    color: #333;
    line-height: 30px;
    top: -10px;
    padding-bottom: 12px;
    border-bottom: 1px dashed #ebeef5;
  }
}

.baseInfo {
  .item {
    line-height: 30px;
    font-size: 14px;
    margin-bottom: 12px;
    label {
      color: #999;
      margin-right: 6px;
    }

    span.value {
      color: #333;

      &.fail {
        color: #ff3838;
      }

      &.success {
        color: #61d9a5;
      }
    }
  }

  .icon_gender {
    width: 16px;
    height: 16px;
  }
}
.userCardInfoContent{
        margin-bottom: 14px;
        .userCardInfoContentItem{
            display: flex;
            align-items: center;
            font-size: 15px;
            height: 38px;
            .label{
                width: 100px;
               
                display: flex;
                align-items: center;
                justify-content: center;
                label{
                    display: block;
                    height:38px;
                    line-height: 38px;
                    width: 65px;
                    text-align: justify;
                    i{
                        display:inline-block;
                        padding-left: 100%;
                        width: 100%;
                    }
                }
            }
           
        }
    }
</style>
<style lang="less">
.selectIdentification {
    margin-top: 14px;
}
.bankMobile{
        .el-input{
            width: 60%;
        }
        .el-input__inner{
            // border: none;
            padding-left: 6px;
        }
}
.code-input{
    .el-input__inner{
            // border: none;
            // padding-left: 0;
          }
}
</style>
